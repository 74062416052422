import React from 'react'
import PropTypes from 'prop-types'

import { FormField, Form, Radio, Modal, Dimmer, Loader, Segment, Header, Button } from 'semantic-ui-react'

import ConfirmCancelButtons from '../../common-components/confirm-cancel-buttons'
import ListLoader from '../../common-components/list-loader'
import CustomDropzone from '../../common-components/custom-dropzone'
import styles from './csv-import-modal.css'


const propTypes = {
  addDomains: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  domainsFetched: PropTypes.bool.isRequired,
  fetchDomains: PropTypes.func.isRequired,
  isDomainID: PropTypes.bool,
  updateDomain: PropTypes.func.isRequired,
  file: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  found: PropTypes.array.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notFound: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  foundCSV: PropTypes.string.isRequired,
  notFoundCSV: PropTypes.string.isRequired,
}

const defaultProps = { file: null }

/* eslint-disable react/prop-types */
const renderDomains = ({ domains }) => (
  <div>
    {domains.map((d) => (
      <div key={d} className={styles.domain}>{d}</div>
    ))}
  </div>
)
/* eslint-enable react/prop-types */

const CSVImportModal = ({
  addDomains,
  closeModal,
  domainsFetched,
  fetchDomains,
  isDomainID,
  updateDomain,
  file,
  fileName,
  found,
  handleFileUpload,
  isLoading,
  notFound,
  open,
  openModal,
  foundCSV,
  notFoundCSV,
}) => (
  <Modal
    trigger={(<Button content='Import Domains from CSV' />)}
    onOpen={openModal}
    onClose={closeModal}
    open={open}
    closeOnDimmerClick={false}
  >
    <Modal.Header content='Import Domains Through CSV' />
    <Modal.Content>
      <Dimmer inverted active={isLoading}>
        <Loader content='Loading' />
      </Dimmer>
      {domainsFetched
        ? (
          <div>
            <Segment.Group horizontal className={styles.domainSegment}>
              <Segment className={styles.equalWidth}>
                <Header content='Domains Not Found' textAlign='center' />
                {notFound.length > 0
                  ? (
                    <ListLoader
                      Component={renderDomains}
                      list={notFound}
                      listName='domains'
                      pageSize={30}
                      scrollingDiv
                    />
                  )
                  : <div className={styles.emptyDiv} />}
              </Segment>
              <Segment className={styles.equalWidth}>
                <Header content='Domains Found' textAlign='center' />
                {found.length > 0
                  ? (
                    <ListLoader
                      Component={renderDomains}
                      list={found.map((d) => d.TLD)}
                      listName='domains'
                      pageSize={30}
                      scrollingDiv
                    />
                  )
                  : <div className={styles.emptyDiv} />}
              </Segment>
            </Segment.Group>
            <div className={styles.downloadButtons}>
              <Button
                content='Not Found CSV'
                icon='download'
                as='a'
                href={notFoundCSV}
                download={`${fileName.slice(0, -4)}-not-found.csv`}
                disabled={notFound.length === 0}
              />
              <Button
                content='Found CSV'
                icon='download'
                floated='right'
                as='a'
                href={foundCSV}
                download={`${fileName.slice(0, -4)}-found.csv`}
                disabled={found.length === 0}
              />
            </div>
          </div>
        )
        : (
          // not only using text/csv for filePickerType because
          // mime type determination is not reliable across platforms.
          // in this case, .csv file in maxOS is recognized as text/csv
          // whereas in Windows it is recognized as application/vnd.ms-excel
          // more detial: https://github.com/react-dropzone/react-dropzone/issues/276
          <div style={{ marginBottom: '1.5rem' }}>
            <CustomDropzone
              handleFileUpload={handleFileUpload}
              note='Only CSV file will be accepted'
              hasFile={file !== null}
              filePickerType='.csv, text/csv, application/vnd.ms-excel'
            />
          </div>
        )}
      <Form>
        <FormField>
          Domain Type:
        </FormField>
        <FormField>
          <Radio
            label='TLD'
            name='radioGroup'
            value={false}
            checked={isDomainID === false}
            onChange={updateDomain}
          />
        </FormField>
        <FormField>
          <Radio
            label='DomainID'
            name='radioGroup'
            value={true}
            checked={isDomainID === true}
            onChange={updateDomain}
          />
        </FormField>
      </Form>
      <ConfirmCancelButtons
        floated='right'
        className={styles.buttons}
        confirmClick={domainsFetched ? addDomains : fetchDomains}
        cancelClick={closeModal}
        confirmContent={domainsFetched ? 'Add Domains' : 'Fetch Domains'}
        disabled={!fileName}
      />
    </Modal.Content>
  </Modal>
)

CSVImportModal.propTypes = propTypes

CSVImportModal.defaultProps = defaultProps

export default CSVImportModal
